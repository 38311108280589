<!-- a svelte component that uses the html5 dialog element -->
<script>
  import Highlight from "svelte-highlight";
  import json from "svelte-highlight/languages/json";
  import "svelte-highlight/styles/github.css";

  import { X, Code } from "lucide-svelte";

  export let title = "Simple Dialog";
  export let code = "This is a simple dialog";

  let dialog = null;

    document.addEventListener("click", (event) => {
        // close the dialog when clicking outside of it
        if (event.target === dialog) {
            closeDialog();
        }
    });

  export function closeDialog() {
    dialog.close();
  }

  export function openDialog() {
    dialog.showModal();
    dialog.querySelector("pre").scrollTop = 0;
  }

  export function setTitle(newTitle) {
    title = newTitle;
  }

  export function setContent(newContent) {
    code = newContent;
  }

  export function renderDialog(title, content) {
    title = title;
    code = content;
    openDialog();
  }
</script>

<dialog bind:this={dialog} class="backdrop:bg-black/25 rounded">
  <div
    class="p-4 flex flex-col justify-start bg-white shadow rounded text-slate-500 w-[min(90vw,800px)] h-[min(90vh,1000px)]"
  >
    <div class="flex flex-row justify-between items-center">
      <h2 class="text-md flex flex-row justify-center items-center gap-2"><Code class="inline-block"/> {title}</h2>
      <button
        class="text-white bg-slate-400 hover:bg-sky-400 px-3 py-2 rounded"
        on:click={() => {
          closeDialog();
        }}
      >
        <X class="w-4 h-4"/>
      </button>
    </div>
    <div class="flex flex-col shrink min-h-2">
      <Highlight class="mt-2 border-2 border-slate-200 grow h-full rounded text-sm overflow-auto" language={json} {code} />
    </div>
  </div>
</dialog>
